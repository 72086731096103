import "../Styles/index.scss";

let navOverlay = document.querySelector(".nav-overlay");
if (navOverlay != null) {
	for (let element of document.querySelectorAll(".nav-toggle")) {
		element.addEventListener("click", e => {
			navOverlay.classList.toggle("is-active");
		});
	}
}

import { initializeMaps } from "@wagich/bluc-map";
initializeMaps();

import retag from "svelte-retag";
if (document.querySelector("reiki-signup") != null) {
	import("./reiki-signup.svelte").then(m => {
		retag({
			component: m.default,
			tagname: "reiki-signup",
		});
	})
}
